import { Controller } from "@hotwired/stimulus"

const id = "copycat"

export default class extends Controller {
  connect() {
    this.element.addEventListener("click", (event) => this.copy(event, this.element))

    if (this.container) return

    const container = document.createElement("div")
    const textArea = document.createElement("textarea")
    container.id = id
    container.appendChild(textArea)
    document.body.appendChild(container)
  }

  copy(event, element) {
    event.preventDefault()

    const clipboardValue = element.dataset.copyToClipboard

    this.container.className = (clipboardValue && clipboardValue.length > 0) ? "text" : "link"
    this.container.style.left = event.clientX + "px"
    this.container.style.top = (event.clientY - 20) + "px"
    this.container.style.display = "block"
    this.textArea.value =  clipboardValue || element.href
    this.textArea.focus()
    this.textArea.select()
    document.execCommand("copy")

    this.container.style.opacity = 0
    setTimeout(() => {
      this.container.style.display = "none"
      this.container.style.opacity = 1
    }, 1500)
  }

  get container() {
    return document.getElementById(id)
  }

  get textArea() {
    return this.container?.querySelector("textarea")
  }
}
